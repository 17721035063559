<div class="panel panel-default">
  <div class="panel-body">
    <a *ngIf="hasAddUserButton" class="btn btn-primary mb20" routerLink="/users/edit/userId/0">
      <span class="glyphicon glyphicon-user"></span> Add New<span class="glyphicon glyphicon-plus"></span>
    </a>
    <div class="col-md-12 padding-8">
      <div class="col-md-5">
        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>Select User Type</strong>
          </div>
          <div class="col-md-8">
            <select class="form-control" [(ngModel)]="getUsersBy" (change)="resetUsers(); getUsers(getUsersBy)">
              <option *ngFor="let option of userTypes" [value]="option.id">{{option.text}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-12 padding-8" *ngIf="getUsersBy == 'dealers'">
          <div class="col-md-4">
            <strong>Select Dealer</strong>
          </div>
          <div class="col-md-8" style="overflow-y: scroll; height:150px; border: 1px #ccc solid; left:15px">
            <label style="display:block" *ngFor="let option of usersOfType">
              <input type="checkbox" [(ngModel)]="multiUsers['dealer'][option.id]" (ngModelChange)="getUsersByRole('dealer', option.id)"> {{option.name}}
            </label>
          </div>
        </div>
        <div class="col-md-12 padding-8" *ngIf="getUsersBy == 'truckdealer'">
          <div class="col-md-4">
            <strong>Select Service Dealer Secondary</strong>
          </div>
          <div class="col-md-8" style="overflow-y: scroll; height:150px; border: 1px #ccc solid; left:15px">
            <label style="display:block" *ngFor="let option of usersOfType">
              <input type="checkbox" [(ngModel)]="multiUsers['truckdealer'][option.id]" (ngModelChange)="getUsersByRole('truckdealer', option.id)"> {{option.name}}
            </label>
          </div>
        </div>

        <div class="col-md-12 padding-8" *ngIf="getUsersBy == 'oems'">
          <div class="col-md-4">
            <strong>Select OEM</strong>
          </div>
          <div class="col-md-8" style="overflow-y: scroll; height:150px; border: 1px #ccc solid; left:15px">
            <label style="display:block" *ngFor="let oem of usersOfType">
              <input type="checkbox" [(ngModel)]="multiUsers['oem'][oem.id]" (ngModelChange)="getUsersByRole('oem', oem.id)"> {{oem.name}}
            </label>
          </div>
        </div>

        <div class="col-md-12 padding-8" *ngIf="getUsersBy == 'clients'">
          <div class="col-md-4">
            <strong>Select Customer</strong>
          </div>
          <div class="col-md-8" style="overflow-y: scroll; height:150px; border: 1px #ccc solid; left:15px">
            <label style="display:block" *ngFor="let customer of usersOfType">
              <input type="checkbox" [(ngModel)]="multiUsers['client'][customer.id]" (ngModelChange)="getUsersByRole('client', customer.id)"> {{customer.name}}
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-1"></div>

      <div class="col-md-1" style="border-left: 2px lightcoral solid; height: 150px" *ngIf="getUsersBy == 'specific'"></div>

      <div class="col-md-5" *ngIf="getUsersBy == 'specific'">
        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>First Name</strong>
          </div>
          <div class="col-md-8">
              <input type="text" class="form-control" [(ngModel)]="userSearch.firstname" placeholder="First Name" />
          </div>
        </div>

        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>Last Name</strong>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" [(ngModel)]="userSearch.lastname" placeholder="Last Name" />
          </div>
        </div>

        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>Email</strong>
          </div>
          <div class="col-md-8">
            <input type="email" class="form-control" [(ngModel)]="userSearch.email" placeholder="Email">
          </div>
        </div>

        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>By User ID</strong>
          </div>
          <div class="col-md-8">
              <input type="number" class="form-control" [(ngModel)]="userSearch.id" placeholder="User ID" />
          </div>
        </div>
        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>By Domain</strong>
          </div>
          <div class="col-md-8">
              <input type="text" class="form-control" [(ngModel)]="userSearch.domain" placeholder="Domain"/>
          </div>
        </div>

        <div class="col-md-12 padding-8">
          <div class="col-md-8 col-md-offset-4">
            <button class="btn btn-primary mb20" type="button" (click)="getUsers('search', userSearch)" [disabled]="!userSearch.firstname && !userSearch.lastname && !userSearch.email && !userSearch.id && !userSearch.domain">
              <span class="glyphicon glyphicon-search"></span> Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>