import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../../../auth/user.service';
import { ChargersDashboardService } from '../chargers-dashboard.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { CommonDataService } from '../../../../../shared/services/common-data.service';
import { ChargerService } from '../../../../../admin/devices/chargers/charger.service';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';
import { Subscription } from 'rxjs';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { Site } from '../../../../../shared/models/site.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-charger-settings',
	templateUrl: './charger-settings.component.html',
	styleUrls: ['./charger-settings.component.css']
})
export class ChargerSettingsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

	@Input() customerID: number = 0;
	@Input() siteID: number = 0;
	@Input() chargersIDs: any = [];
	@Input() hasChargers = false;
	charger: any = {};
	currentUser: any = {};
	changesStack: any = {};
	userAccess: any = {};
	cableSettings: any = {};
	batteryTypeRestricts: any = {};
	chargersInfoObj: any = {};
	originalChargersInfo: any = [];
	batteryVoltages: number[] = [];
	oldCharger: any = {};
	formatTemperature: string = '';
	formName: string = '';
	tab: string = 'CARDS';
	stage: string = '';
	chargerVoltages: any[] = [
		'fivoltage',
		'cvvoltage',
		'tricklevoltage'
	];
	temperatureFields: any = {
		'cooldowntemperature': '',
		'foldtemperature': '',
		'trtemperature': '',
		'maxtemperaturefault': ''
	};
	minChargerFirmwareVersion: number = 0;
	requestedBmsId: number = 0;
	chargerPlant: any = {'temperature': 0};
	isBulk: boolean = false;
	newLithiumBattery: boolean = false;
	inconsistentOperation: boolean = false;
	trVoltageLabel: string = '';
	trRateLabel: string = '';
	daysOfTheWeek: any[]			= [];
	TRCurrentRates: any[]			= [];
	CCCurrentRates: any[]			= [];
	FICurrentRates: any[]			= [];
	EQCurrentRates: any[]			= [];
	cvFinishCurrents: any[]			= [];
	finishDvVoltages: any[]			= [];
	timeIntervals: any[]			= [];
	quartsIntervals: any[]			= [];
	quarts_2_18: any[]				= [];
	quarts_6_18: any[]				= [];
	quarts_2_6: any[]				= [];
	quarts_1_6: any[]				= [];
	quarts_1_5: any[]				= [];
	limitingFactors: any[]			= [];
	currentRampMinimumSteps: any[]	= [];
	cvCurrentSteps: any[]			= [];
	finishDt: any[]					= [];
	cableGaugeOptions: any[]		= [];
	PMinputVoltages: any[]			= [];
	pmVoltages: any[]				= [];
	bmsTypes: any[]					= [];
	otherBmsTypes: any[]			= [];
	plantTemperatures: any[]		= [];
	batteryTypes: any[]				= [];
	chargerTypes: any[]				= [];
	resistancePerFoot: any			= {};
	dirtyFields: any				= {};
	cableSettingsModalSubscription:Subscription;
	currentSiteSubscription: Subscription;
	currentSite: Site;


	@Output() onMultipleOptionsFlag = new EventEmitter<boolean>();
	@Output() onGetChargerInfo = new EventEmitter<object>();

	@ViewChild("saveOptionsModal") saveOptionsModal;
	@ViewChild("chargerProfileModal") chargerProfileModal;
	@ViewChild("cableSettingsModal") cableSettingsModal;
	@ViewChild("changeBmsTypeModal") changeBmsTypeModal;

	constructor(
		public userService: UserService,
		public commonUtil: CommonUtil,
		private elementRef: ElementRef,
		private renderer: Renderer2,
		private chargerService: ChargerService,
		private notificationMessage: NotificationMessageService,
		public commonData: CommonDataService,
		private devicesDashboard: DevicesDashboardService,
		private chargersDashboard: ChargersDashboardService,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService
	) {
		devicesDashboard.settingsDeviceType = "MCB";
	}

	ngOnInit() {

		this.finishDt			= this.commonUtil.getDropDownListData('number', {start:1, end:59, step:1});
		this.batteryTypes		= this.devicesDashboard.batteryTypes;
		this.plantTemperatures	= [{id:0, text:"<65"}, {id:1, text:"65-90"}, {id:2, text:">90"}];
		this.chargerTypes		= this.devicesDashboard.chargerTypes;
		this.pmVoltages			= [{id:36, text:"36"}, {id:48, text:"48"}, {id:80, text:"80"}];
		this.bmsTypes			= this.devicesDashboard.bmsTypes;
		this.PMinputVoltages	= [{id:0, text:"480"}, {id:1, text:"208"}, {id:2, text:"600"}, {id:3, text:"380"}];
		this.resistancePerFoot	= {"1/0": 0.105, "2/0": 0.084, "3/0": 0.067, "4/0": 0.053};
		this.cableGaugeOptions	= Object.keys(this.resistancePerFoot);
		this.daysOfTheWeek		= this.commonUtil.getDropDownListData('week');

		let cvCurrentSteps			= this.commonUtil.getDropDownListData('number', {start:0, end:10, step:0.5});
		let cvCurrentStepsValues	= cvCurrentSteps.slice();
		cvCurrentStepsValues[0]		= 'Default';
		this.cvCurrentSteps			= this.commonUtil.arrayToArrayOfObjects(cvCurrentSteps, cvCurrentStepsValues);

		let currentRampMinimumSteps		= this.commonUtil.getDropDownListData('number', {start:0, end:60, step:5});
		let currentRampMinimumStepsVals	= currentRampMinimumSteps.slice();
		currentRampMinimumStepsVals[0]	= 'Default';
		this.currentRampMinimumSteps	= this.commonUtil.arrayToArrayOfObjects(currentRampMinimumSteps, currentRampMinimumStepsVals);

		this.TRCurrentRates		= this.devicesDashboard.TRCurrentRates;
		this.CCCurrentRates		= this.devicesDashboard.CCCurrentRates;
		this.FICurrentRates		= this.devicesDashboard.FICurrentRates;
		this.EQCurrentRates		= this.devicesDashboard.EQCurrentRates;
		this.cvFinishCurrents	= this.devicesDashboard.cvFinishCurrents;
		this.finishDvVoltages	= this.devicesDashboard.finishDvVoltages;
		this.timeIntervals		= this.devicesDashboard.timeIntervals;
		this.quartsIntervals	= this.devicesDashboard.quartsIntervals;
		this.quarts_2_18		= this.commonUtil.getDropDownListData('clock',  {start:0, end:18, step:15, ignoreFirst: 1});
		this.quarts_6_18		= this.devicesDashboard.quarts_6_18;
		this.quarts_2_6			= this.devicesDashboard.quarts_2_6;
		this.quarts_1_6			= this.devicesDashboard.quarts_1_6;
		this.limitingFactors	= this.devicesDashboard.limitingFactors;
		this.quarts_1_5			= this.commonUtil.getDropDownListData('clock',  {start:1, end:5, step:15});

		this.currentUser = this.userService.getCurrentUser();
		this.userAccess = this.currentUser.userAccess;

		this.currentSiteSubscription = this.sideMenuService.currentSite.subscribe(data => {
			if(data && data.id)
				this.currentSite = data;
		});

		if(this.chargersIDs.length > 1) {
			this.isBulk = true;
			this.hasChargers = true;
		}

		this.devicesDashboard.isBulkSettings = this.isBulk;
	}

	ngAfterViewInit() {
		if(!this.userService.hasAccessFunction('readonly_settings')) {
			this.cableSettingsModalSubscription = this.cableSettingsModal.onClose.subscribe(() => {
				this.updateCableSettings('Cancel');
			});
		}
	}

	ngOnChanges(changes: any) {
		if(changes.chargersIDs && changes.chargersIDs.currentValue && changes.chargersIDs.currentValue.length > 0)
			this.getChargersSettingsInfo();
	}

	setTab(tabId, doChecks = false){

		let batteryInfoTabs	= ["FI-EQ-SCHEDULING", "DEFAULT-CHARGE-PROFILE"];
		let checkTabs		= ["BATTERY-INFO"].concat(batteryInfoTabs);
		let strictsCount	= Object.keys(this.batteryTypeRestricts).length;

		if(!doChecks || checkTabs.indexOf(tabId) == -1 || strictsCount == 0
			|| (tabId == "BATTERY-INFO" && strictsCount == 1 && this.batteryTypeRestricts.liion_cellvoltage)
		) {
			this.tab = tabId;
			return;
		}

		let msg: string;

		if(this.batteryTypeRestricts.batterytype) {

			if(this.isBulk) {
				msg = "Battery type changes are pending:<br/>";
				msg += this.batteryTypeRestricts.batterytype.join(', ');
			} else {
				msg = "Battery type change is pending.";
			}
		} else if(this.batteryTypeRestricts.enableautodetectmultivoltage) {
			if(this.isBulk) {
				msg = "Multi voltage setting changes are pending:<br/>";
				msg += this.batteryTypeRestricts.enableautodetectmultivoltage.join(', ');
			} else {
				msg = "Multi voltage setting change is pending.";
			}
		} else if(this.batteryTypeRestricts.chargertype) {
			if(this.isBulk) {
				msg = "Charger type setting changes are pending:<br/>";
				msg += this.batteryTypeRestricts.chargertype.join(', ');
			} else {
				msg = "Charger info setting change is pending.";
			}
		} else if(batteryInfoTabs.indexOf(tabId) != -1 && this.batteryTypeRestricts.liion_cellvoltage) {
			if(this.isBulk) {
				msg = "Li-ion cell voltage setting changes are pending:<br/>";
				msg += this.batteryTypeRestricts.liion_cellvoltage.join(', ');
			} else {
				msg = "Battery cell voltage change is pending.";
			}
		}

		this.notificationMessage.setMessage(msg, 'warning', 30000);
	};

	isSet(tabId){
		return this.tab === tabId;
	};

	batteryTypeChanged(batterytype) {
		if(batterytype == 1 && this.minChargerFirmwareVersion > 2.5)
			this.newLithiumBattery = true;
		else
			this.newLithiumBattery = false;
	}

	updateTemperatureShift() {
		if(this.chargerPlant.temperature === 0)
			this.charger.nominal_temperature_shift = 18;
		else if(this.chargerPlant.temperature == 1)
			this.charger.nominal_temperature_shift = 25;
		else
			this.charger.nominal_temperature_shift = 32;
	}

	resetForms(removeErrors = false) {
		if(this.chargersIDs.length > 0 && !removeErrors) {
			this.charger = Object.assign({}, this.oldCharger);
		}
		
		if(this.chargersIDs.length > 0 || removeErrors) {
			let invalidInputs = this.elementRef.nativeElement.querySelectorAll('.invalid-input');
			invalidInputs.forEach((item) => {
				item.classList.remove('invalid-input');
			});
		}

		this.dirtyFields = {};
	}

	getStackHTML(field) {
		var stack = this.changesStack;

		if(!stack || !stack[field])
			return "";

		var returnHTML = `
				<p class='stackHTML'>
					<strong class='tcenter'>Changes Stack</strong>
				</p>
				<table class='stackHTML-table'>
					<tr><th>Value</th><th>User Name</th></tr>`;

		stack[field].forEach((item) => {
			returnHTML += "<tr><td style='color: white'>" + item[0] + "</td><td>" + item[1] + "</td></tr>";
		});
		
		returnHTML += "</table>";

		return returnHTML;
	}

	deselectAllDays(field) {
		if(this.charger) {
			this.charger[field] = [];
		}
	}

	updateCableSettings(stage) {

		if(stage == 'OK') {

			let cable = this.cableSettings;

			if(cable.chargerLength && cable.batteryLength && cable.number && cable.gauge) {
				var totalLength = 2 * (cable.chargerLength + cable.batteryLength);
				if(cable.number == 2)
					totalLength *= 2;
				
				var resistancePerFoot = this.resistancePerFoot[cable.gauge];
				this.charger.ir = (totalLength * resistancePerFoot).toFixed(2);
			}
		}
		this.cableSettings = {};

		this.cableSettingsModal.hide();
	}

	addCommandToChargerQueue(id, command, options) {
		this.chargersDashboard.addCommandToChargerQueue(id, command, options).subscribe((data) => {
			if(command == 'pushBmsFirmware')
				this.getChargersSettingsInfo();
		});
	}

	getChargersSettingsInfo() {

		this.batteryTypeRestricts = {};

		if(this.chargersIDs.length == 0)
			return;

		this.chargersDashboard.getChargersSettingsInfo(this.chargersIDs).subscribe(
			(chargers: any) => {

				this.originalChargersInfo = chargers.settings.map(x => Object.assign({}, x));
				this.chargersInfoObj = Object.assign({}, this.commonUtil.arrayToAssociativeArray(this.originalChargersInfo));
				
				let minChargerFirmwareVersion = 0;
				if(chargers.settings.length > 0)
					minChargerFirmwareVersion = chargers.settings[0].firmwareversion;
				
				if(this.isBulk) {
					this.formatTemperature = 'F';
					chargers.settings.forEach((aCharger) => {
						for(let field in this.temperatureFields) { //In case of bulk: Always show temperature fields in F format
							aCharger[field] = this.commonUtil.fahToCel(aCharger[field], true);
						}
						if(aCharger.firmwareversion < minChargerFirmwareVersion)
							minChargerFirmwareVersion = aCharger.firmwareversion;
						if(aCharger.pmmaxcurrent == 0) {
							aCharger.pmmaxcurrent = 99;
						}
					});
				}

				this.minChargerFirmwareVersion = minChargerFirmwareVersion;

				if(this.isBulk) {
					chargers.settings = this.commonUtil.getArrayOfObjectsIntersection(chargers.settings);
				} else {
					chargers.settings = chargers.settings[0];
					chargers.settings.wifi_at_version = this.commonUtil.Format_WIFI_AT_Version(chargers.settings.wifi_at_version);
					if (chargers.settings.pmmaxcurrent == 0) {
						chargers.settings.pmmaxcurrent = 99;
					}
					if(chargers.settings.defaultbrightness)
						chargers.settings.defaultbrightness = chargers.settings.defaultbrightness / 2;
				}

				if(chargers.settings.installationdate !== null)
					chargers.settings.installationdate = new Date(chargers.settings.installationdate);
				
				this.oldCharger = Object.assign({}, chargers.settings);

				this.charger = chargers.settings;

				if(this.isBulk && (this.charger.batterytype === null || this.charger.enableautodetectmultivoltage === null || this.charger.chargertype === null
					|| (this.charger.batterytype == 1 && this.charger.liion_cellvoltage === null))
				) {
					this.inconsistentOperation = true;
				}

				let changesStack = {};
				let batteryTypeRestricts: any = {};

				for(let chargerID in chargers.changesStack) {
					let stack = chargers.changesStack[chargerID];
					changesStack[chargerID] = this.chargersDashboard.formatChargerQueuedChanges(this.charger, stack);

					if(changesStack[chargerID].hasOwnProperty('batterytype')) {
						if(!batteryTypeRestricts.batterytype)
							batteryTypeRestricts.batterytype = [];

						batteryTypeRestricts.batterytype.push(this.chargersInfoObj[chargerID].chargerusername);
					}

					if(this.charger.batterytype == 1 && this.minChargerFirmwareVersion > 2.5) {
						//is newLithiumBattery
						if(changesStack[chargerID].hasOwnProperty('enableautodetectmultivoltage')) {

							if(!batteryTypeRestricts.enableautodetectmultivoltage)
								batteryTypeRestricts.enableautodetectmultivoltage = [];

							batteryTypeRestricts.enableautodetectmultivoltage.push(this.chargersInfoObj[chargerID].chargerusername);
						}

						if(changesStack[chargerID].hasOwnProperty('chargertype')) {

							if(!batteryTypeRestricts.chargertype)
								batteryTypeRestricts.chargertype = [];

							batteryTypeRestricts.chargertype.push(this.chargersInfoObj[chargerID].chargerusername);
						}

						if(changesStack[chargerID].hasOwnProperty('liion_cellvoltage')) {

							if(!batteryTypeRestricts.liion_cellvoltage)
								batteryTypeRestricts.liion_cellvoltage = [];

							batteryTypeRestricts.liion_cellvoltage.push(this.chargersInfoObj[chargerID].chargerusername);
						}
					}
				}

				if(Object.keys(batteryTypeRestricts).length > 0)
					this.setTab('CARDS');

				this.batteryTypeRestricts = batteryTypeRestricts;
				
				if(this.isBulk)
					this.changesStack = changesStack;
				else
					this.changesStack = changesStack[this.chargersIDs[0]];

				for(let field in this.temperatureFields) { //save original temperature fields value
					this.temperatureFields[field] = this.charger[field];
				}

				let step = 12;
				if(this.charger.firmwareversion > 2.1)
					step = 2;
				
				let end = chargers.settings.pmvoltage;

				if(end == 80)
					end = 48;

				this.batteryVoltages = this.commonUtil.getDropDownListData('number', {'start': 24, 'end': end, 'step': step});

				if(chargers.settings.pmvoltage == 80)
					this.batteryVoltages.push(72, 80);

				this.chargerPlant.temperature = this.charger.nominal_temperature_shift <= 18 ? 0 : (this.charger.nominal_temperature_shift == 25 ? 1 : 2);

				this.charger.restart_time_fmt = this.commonUtil.secondsToElapsedTime(this.charger.restart_time * 3600) || '0 hours';

				var newLithiumBattery = false;
				var trRateLabel = this.translateService.instant('charger.tr_current_rate');
				var trVoltageLabel = this.translateService.instant('charger.tr_target_voltage');
			
				if(this.charger.batterytype == 1 && this.minChargerFirmwareVersion > 2.5) {
					newLithiumBattery = true;
					this.TRCurrentRates	= this.commonUtil.getDropDownListData('number', {start:3, end:100, step:1});
					this.FICurrentRates	= this.commonUtil.getDropDownListData('number', {start:1, end:26, step:0.5});
					trRateLabel = this.translateService.instant('charger.cc1_current_rate');
					trVoltageLabel = this.translateService.instant('charger.cv1_target_voltage');
				} else {
					this.TRCurrentRates	= this.devicesDashboard.TRCurrentRates;
					this.FICurrentRates	= this.devicesDashboard.FICurrentRates;
				}
				
				this.trRateLabel = trRateLabel;
				this.trVoltageLabel = trVoltageLabel;
				this.newLithiumBattery = newLithiumBattery;

				if(this.newLithiumBattery) {

					var voltageFactor = 1.5;
					if(!this.charger.enableautodetectmultivoltage)
						voltageFactor = this.charger.liion_cellvoltage / 2;
					
					for(var field of this.chargerVoltages) {

						if(this.charger[field] === null)
							continue;

						this.charger[field]		= +(this.charger[field] * voltageFactor).toPrecision(3);
						this.oldCharger[field]	= this.charger[field];

						if(this.changesStack.hasOwnProperty(field)) {
							this.changesStack[field].forEach(function(item) {
								item[0] = +(item[0] * voltageFactor).toPrecision(3);
							});
						}
					}
				}

				if(!this.isBulk) {
					this.formatTemperature = 'C';
					if(Object.keys(this.charger).length > 0) {
						if(!this.charger.temperatureformat) {

							this.formatTemperature = 'F';
							for(let field in this.temperatureFields) { //temperatureformat is F so; convert C -> F
								this.oldCharger[field]	= this.commonUtil.fahToCel(this.charger[field], true);
								this.charger[field]		= this.commonUtil.fahToCel(this.charger[field], true);
							}
						}
					}

					let multipleOptions: boolean = false;
					
					if((this.userService.hasAccessFunction('act_admin_device_management') && this.charger.enableplc)
						|| (this.currentUser.isACTuser && this.charger.time_gap > 0) || this.userService.hasAccessFunction('restart_devices')
					) {
						multipleOptions = true;
					}

					this.onMultipleOptionsFlag.emit(multipleOptions);
					this.onGetChargerInfo.emit(this.charger);
				}
			}
		);
	}

	checkChanges(formName, checkBatteryType = false) {

		if(checkBatteryType && this.isBulk) {
						
			var msg = '';
			if(this.charger.batterytype === null)
				msg = this.translateService.instant('charger.changing_settings_err_msg_1');
			else if(this.charger.enableautodetectmultivoltage === null)
				msg = this.translateService.instant('charger.changing_settings_err_msg_2');

			if(msg) {
				this.notificationMessage.setMessage(this.translateService.instant('charger.changing_settings_err_msg_3'));
				return;
			}
		}

		let freshCharger = this.charger;
		let dirtyFields = {};
		let oldCharger = this.oldCharger;
		this.formName = formName;

		this.resetForms(true);

		let arrayFields = this.chargersDashboard.arrayFields;

		for (let key in oldCharger) {
			
			if(arrayFields.indexOf(key) != -1) {

				if(oldCharger[key] && freshCharger[key] && !this.commonUtil.arrayCompare(oldCharger[key], freshCharger[key])) {
					dirtyFields[key] = freshCharger[key];
				}
			} else if(oldCharger[key] != freshCharger[key]) {
			
				dirtyFields[key] = freshCharger[key];
			}
		}

		if(Object.keys(dirtyFields).length > 0) {

			var voltageFactor = 1.5;
			if(!this.charger.enableautodetectmultivoltage)
				voltageFactor = this.charger.liion_cellvoltage / 2;

			for(let fieldName in dirtyFields) {

				if(this.temperatureFields.hasOwnProperty(fieldName)) {
					if(!this.charger.temperatureformat) {

						for(let field in this.temperatureFields) {
							dirtyFields[field] = this.commonUtil.fahToCel(this.charger[field]);
						}
					} else {

						dirtyFields[fieldName] = this.charger[fieldName];
					}
				} else if(fieldName == 'installationdate') {
					
					dirtyFields[fieldName] = new Date(new Date(-this.charger[fieldName].getTimezoneOffset() * 60000 + this.charger[fieldName].getTime()));
				} else if(this.newLithiumBattery && this.chargerVoltages.indexOf(fieldName) != -1) {

					dirtyFields[fieldName] = +(dirtyFields[fieldName] / voltageFactor).toPrecision(3);
				} else {
					dirtyFields[fieldName] = this.charger[fieldName];
				}
			}

			let invalidFields = this.chargerService.validateChargerSettings(dirtyFields, this.isBulk, oldCharger, this.originalChargersInfo);
			this.dirtyFields = dirtyFields;
			
			if(invalidFields.length > 0) {
				this.highlightInvalidFields(invalidFields);
			} else {

				if(this.userService.hasAccessFunction('act_admin_device_management')
					&& (oldCharger.actviewenable || oldCharger.actviewenable === null)) {
					this.saveOptionsModal.show();
				} else {
					this.userSaveOption();
				}
			}
		}
	}

	highlightInvalidFields(invalidFields) {
		let forms = this.elementRef.nativeElement.querySelectorAll('form');
				
		forms.forEach((form) => {
			
			if(form.getAttribute('name') == this.formName) {
				for(let idx in form) {
					let ele = form[idx];
					if(Number.isInteger(Number(idx))) {
						if(invalidFields.indexOf(ele.getAttribute('name')) != -1)
							this.renderer.addClass(ele, 'invalid-input');
					}
				}
			}
		});
		this.notificationMessage.setMessage('Invalid input!');
	}

	userSaveOption = function(stage = 'queue') {

		this.stage = stage;

		if(!this.dirtyFields.hasOwnProperty('chargertype') && !this.dirtyFields.hasOwnProperty('batterytype'))
			return this.saveChargerSettings();

		this.chargerProfileModal.show();
	}

	saveChargerSettings() {

		if(this.oldCharger.actviewenable === null && this.stage == 'queue') {

			var errorMessage = this.translateService.instant('charger.act_intelligent_not_enabled') + "<br/>";
			var notActviewenabledChargers = [];
			this.originalChargersInfo.forEach((chargerTemp) => {
				if(chargerTemp.actviewenable == false)
					notActviewenabledChargers.push(chargerTemp.chargerusername + ' (' + chargerTemp.serialnumber + ')');
			});
			
			errorMessage += notActviewenabledChargers.join('<br/>');
			this.notificationMessage.setMessage(errorMessage, 'danger', 30000);

		} else {

			let dirtyFields = this.chargersDashboard.chargerUpdatedFieldsEffect(this.dirtyFields, this.oldCharger);
			dirtyFields.ids = this.chargersIDs;

			this.chargersDashboard.saveChargerSettings(this.stage, dirtyFields).subscribe((response: any) => {
				if(response.httpStatus == 'error') {
					if(response.type == 'invalidFields') {

						this.highlightInvalidFields(response.data);
					} else if(response.type == 'info') {

						var errorMessage = this.translateService.instant('charger.err_occured_changes') + "<br/>";
						var faultedChargers = response.data.join('<br/>');
						errorMessage += faultedChargers;
						this.notificationMessage.setMessage(errorMessage, 'danger', 30000);
					}
				} else {
					
					this.getChargersSettingsInfo();
				}
			});
		}
	};

	openChangeBmsTypeModal() {
		var otherBmsTypes = [];
		//commenting out for testing, but need this 
		// otherBmsTypes = this.bmsTypes.filter((item) => this.charger.bms_id != item.id);
		this.otherBmsTypes = this.bmsTypes;
		this.changeBmsTypeModal.show();
	}

	hideChangeBmsTypeModal() {
		this.requestedBmsId = null;
		this.changeBmsTypeModal.hide();
	}

	ngOnDestroy() {
		if(this.cableSettingsModalSubscription)
			this.cableSettingsModalSubscription.unsubscribe();
		if(this.currentSiteSubscription) 
			this.currentSiteSubscription.unsubscribe();
	}
}