import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from '../../../admin.service';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-dealer-edit',
	templateUrl: './dealer-edit.component.html',
	styleUrls: ['./dealer-edit.component.css']
})
export class DealerEditComponent implements OnInit, OnDestroy {

	dealer: any = {
		id: 0,
		name: '',
		act_user_id: null
	};

	ACTusers = <any>[];
	pageType: string = 'dealer';
	isTruckDealer: boolean = false;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private router: Router,
		private breadcrumb: BreadcrumbService
	) {
	}

	ngOnInit() {
		this.pageType = this.route.snapshot.data['pageType'] || 'dealer';
		this.isTruckDealer = this.pageType == 'truck_dealer';

		let breadcrumb = [];
		if(this.isTruckDealer) {
			breadcrumb.push({'label': 'Service Dealer Secondary', 'url': '/truck-dealers'});
		} else {
			breadcrumb.push({'label': 'Dealers', 'url': '/dealers'});
		}
		breadcrumb.push({'label': 'Edit', 'url': ''});

		this.breadcrumb.setBreadcrumb(breadcrumb);
		this.route.params.subscribe(
			params => {
				this.dealer['id'] = Number(params['dealerId']);
		
				if(this.dealer['id'])
					this.getDealerInfo(this.dealer['id']);
				else
					this.getUserNamesByRole('act');
			}
		);
	}

	getDealerInfo(id) {
		this.adminService.getDealers(id, this.pageType, true).subscribe(
			data => {
				this.dealer		= data['dealers'];
				this.ACTusers	= data['ACTusers'];
			}
		);
	}

	getUserNamesByRole(role) {
		this.adminService.getUserNamesByRole(role).subscribe(
			(data: Object[]) => {

				data.forEach(function(user) {
					user['name'] = user['firstname'] + ' ' + user['lastname'];
				});
				
				this.ACTusers = data;
			}
		);
	}

	updateDealer() {
		this.adminService.updateDealer(this.dealer, this.pageType).subscribe(
			() => {
				if(this.isTruckDealer) {
					this.router.navigate(['/truck-dealers']);
				} else {
					this.router.navigate(['/dealers']);
				}
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
