import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../../../auth/user.model';
import { AdminService } from '../../../admin.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-user-edit',
	templateUrl: './user-edit.component.html',
	styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit, OnDestroy {

	user: any;
	roles: any;
	dealers: any;
	truckDealers: any;
	oems: any;
	customers: any;
	constructor(
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		private route: ActivatedRoute,
		private navigation: NavigationService,
		private breadcrumb: BreadcrumbService
	) { }

	ngOnInit() {
		this.user = new User();
		this.user.id = +(this.route.snapshot.params.userId);
		this.user.roleid = null;

		this.getRoles();
		this.getCustomers();
		this.getDealers();
		this.getTruckDealers();
		this.getOEMs();

		if(this.user.id != 0)
			this.getUsers();

		this.breadcrumb.setBreadcrumb([
			{label: "Users", url: "/users"},
			{label: "Edit", url: ""}
		]);
	}
	ngOnDestroy() {
		this.breadcrumb.hide();
	}

	getUsers(){
		this.adminService.getUsers(this.user.id, this.user.id != 0).subscribe(
			(response: {user: any}) => {
				this.user = response.user;
			}
		);
	}

	saveUser(){
		this.adminService.saveUser(this.user).subscribe(
			(response: any) => {
				if(response && response.error) {
					this.notificationMessage.setMessage(response.error, 'danger', 10000, true);
				} else {
					this.notificationMessage.setMessage('globalSuccessMsg', 'success', 10000, true);
					this.goBack();
				}
			}
		);
	}

	isPasswordRequired(){
		return !+(this.user.id);
	}

	getRoles(){
		this.adminService.getRoles().subscribe(
			response => {
				this.roles = response;
				this.roles.unshift({id: 0, name:"Select Role"});
			}
		);
	};

	getCustomers() {
		this.adminService.getCustomers().subscribe(
			(response: {list: any}) => {
				this.customers = response.list;
				this.customers.unshift({id: 0, name:"Select Company"});
			}
		);
	};

	getDealers() {
		this.adminService.getDealers().subscribe(
			(response: {dealers: any}) => {
				this.dealers = response.dealers;
				this.dealers.unshift({id: 0, name:"Select a Dealer"});
			}
		);
	};

	getTruckDealers() {
		this.adminService.getDealers(0, 'truck_dealer').subscribe(
			(response: {dealers: any}) => {
				this.truckDealers = response.dealers;
				this.truckDealers.unshift({id: 0, name:"Select a Service Dealer Secondary"});
			}
		);
	};

	getOEMs() {
		this.adminService.getOEMs().subscribe(
			response => {
				this.oems = response;
				this.oems.unshift({id: 0, name:"Select a Battery OEM"});
			}
		);
	};

	goBack(){
		this.navigation.goBack(['/users']);
	}
}
